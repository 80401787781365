const Modal = require('./Modal');
const CustomSelector = require("../../../../components/CustomSelector.vue");

var notifStack = [];

class Ui {

    /**
     *
     * @param message
     * @param status
     */
    static notif(message, status) {
        let alertItem = document.createElement('div');
        alertItem.className = "notification " + (status ? status : "");
        alertItem.innerHTML = message;

        document.body.appendChild(alertItem);

        var pos = notifStack.length;
        notifStack.push(alertItem);

        setTimeout(function () {
            alertItem.classList.add('active');
            if (pos > 0) {
                alertItem.style.bottom = 15 + Math.round((5 + 48) * pos) + 'px';
            }
            setTimeout(function () {
                alertItem.classList.remove('active');
                notifStack.splice(0, 1);
                notifStack.forEach((el, i) => {
                    el.style.bottom = 15 + Math.round((5 + 48) * i) + 'px';
                });
                setTimeout(function () {
                    alertItem.remove();
                }, 200);
            }, 5000);
        }, 200);
    }

    /**
     *
     * @param message
     * @param onConfirm
     * @param confirmTextButton
     */
    static alert(message, onConfirm, confirmTextButton) {

        let modal = new Modal({
            modalId: 'alert-modal',
            size: 'small'
        });

        if (typeof confirmTextButton === 'undefined') {
            confirmTextButton = 'Ok';
        }

        let c = document.createElement('div');
        c.className = 'confirm-container wrapper';

        let messageContainer = document.createElement('p');
        messageContainer.className = 'confirm-message';
        messageContainer.innerHTML = message;

        let btnContainer = document.createElement('div');
        btnContainer.className = 'confirm-buttons center';

        let btnValidate = document.createElement('a');
        btnValidate.href = '#';
        btnValidate.className = 'btn btn-m';
        btnValidate.onclick = function (e) {
            e.preventDefault();
            btnValidate.classList.add('loading');
            if (onConfirm) {
                onConfirm(modal);
            } else {
                modal.dismiss();
                return true;
            }
        };
        btnValidate.innerHTML = confirmTextButton;

        btnContainer.append(btnValidate);

        c.append(messageContainer);
        c.append(btnContainer);
        modal.setContentDom(c);
        modal.show();
    }

    /**
     *
     * @param message
     * @param onConfirm
     * @param onCancel
     * @param confirmTextButton
     * @param cancelTextButton
     */
    static confirm(message, onConfirm, onCancel, confirmTextButton, cancelTextButton) {
        let modal = new Modal({
            modalId: 'confirm-modal',
            size: 'small',
            onClose: onCancel,
        });

        if (typeof confirmTextButton === 'undefined') {
            confirmTextButton = 'Confirmer';
        }

        if (typeof cancelTextButton === 'undefined') {
            cancelTextButton = 'Annuler';
        }

        let c = document.createElement('div');
        c.className = 'confirm-container wrapper';

        let messageContainer = document.createElement('p');
        messageContainer.className = 'confirm-message';
        messageContainer.innerHTML = message;

        let btnContainer = document.createElement('div');
        btnContainer.className = 'confirm-buttons';

        let btnValidate = document.createElement('a');
        btnValidate.href = '#';
        btnValidate.className = 'btn btn-m right';
        this._confirm = (callback) => {
            btnValidate.classList.add('loading');
            if (callback) {
                callback(modal);
            } else {
                modal.dismiss();
                return true;
            }
        };
        btnValidate.onclick = (e) => {
            e.preventDefault();
            this._confirm(onConfirm);
        };
        btnValidate.innerHTML = confirmTextButton;

        let btnCancel = document.createElement('a');
        btnCancel.href = '#';
        btnCancel.className = 'btn btn-m gr';
        this._cancel = (callback) => {
            if (callback) {
                callback(modal);
            } else {
                modal.dismiss();
                return false;
            }
        };
        btnCancel.onclick = (e) => {
            e.preventDefault();
            this._cancel(onCancel);
        }
        btnCancel.innerHTML = cancelTextButton;

        btnContainer.append(btnCancel);
        btnContainer.append(btnValidate);

        c.append(messageContainer);
        c.append(btnContainer);
        modal.setContentDom(c);
        modal.show();
    }

    /**
     *
     * @param message
     * @param onConfirm
     * @param inputs string[] inputs {}
     * @param onCancel
     * @param confirmTextButton
     * @param cancelTextButton
     * @param modalOptions
     */
    static ask(message, onConfirm, inputs, onCancel, confirmTextButton, cancelTextButton, modalOptions = {}) {
        if (!modalOptions.size) {
            modalOptions.size = "small";
        }
        if (!modalOptions.modalId) {
            modalOptions.modalId = "ask-modal";
        }
        let modal = new Modal(modalOptions);

        if (typeof confirmTextButton === 'undefined' || confirmTextButton == null) {
            confirmTextButton = 'Confirmer';
        }

        if (typeof cancelTextButton === 'undefined' || cancelTextButton == null) {
            cancelTextButton = 'Annuler';
        }

        let c = document.createElement('div');
        c.className = 'confirm-container wrapper';

        let messageContainer = document.createElement('p');
        messageContainer.className = 'confirm-message';
        messageContainer.innerHTML = message;

        let inputsHTML = [];

        let inputContainer = document.createElement('form');
        inputContainer.action = "#";
        inputContainer.addEventListener('submit', e => {
            e.preventDefault();
        });
        inputContainer.className = "no-border";

        if (inputs) {
            for (let i = 0; i < inputs.length; i++) {
                let input;
                let field = document.createElement('div');
                field.className = "field dir-vertical";
                const uid = "input-" + uniqid();

                switch (inputs[i].type) {
                    case 'textarea':
                        input = document.createElement("textarea");
                        input.placeholder = inputs[i].placeholder ? inputs[i].placeholder : "";
                        input.innerHTML = inputs[i].value ? inputs[i].value : "";
                        break;
                    case 'select':
                        input = document.createElement("select");
                        inputs[i].options.forEach(opt => {
                            const option = document.createElement("option");
                            option.value = typeof opt === 'object' ? opt.value : opt;
                            option.innerHTML = typeof opt === 'object' ? opt.content : opt;
                            input.appendChild(option);
                        });
                        break;
                    case 'checkbox':
                    case 'radio':
                        input = document.createElement("input");
                        input.type = inputs[i].type;
                        input.value = inputs[i].value;
                        input.checked = inputs[i].checked ? inputs[i].checked : false;
                        field.classList.remove("dir-vertical");
                        break;
                    default:
                        input = document.createElement("input");
                        input.type = inputs[i].type ? inputs[i].type : 'text';
                        input.placeholder = inputs[i].placeholder ? inputs[i].placeholder : "";
                        input.value = inputs[i].value ? inputs[i].value : "";
                        break;
                }

                let label;
                if (inputs[i].label) {
                    label = document.createElement('label')
                    label.className = "label";
                    label.innerHTML = inputs[i].label;
                    label.setAttribute('for', uid);
                    if (inputs[i].type !== "checkbox" && inputs[i].type !== "radio") {
                        field.appendChild(label);
                    }
                }

                if (inputs[i].type !== "checkbox" && inputs[i].type !== "radio") {
                    input.className = "input";
                } else {
                    field.classList.add(inputs[i].type);
                }

                if (inputs[i].classname) {
                    field.classList.add(inputs[i].classname);
                }

                input.required = inputs[i].required;
                input.name = inputs[i].name ? inputs[i].name : "input_" + i;
                input.id = uid;

                if (inputs[i].type !== 'textarea') {
                    input.onkeydown = (e) => {
                        if (pressEnter(e)) {
                            btnValidate.dispatchEvent(new Event("click"))
                        }
                    };
                }

                field.appendChild(input);
                if ((inputs[i].type === "checkbox" || inputs[i].type === "radio") && label) {
                    field.appendChild(label);
                }
                inputContainer.appendChild(field);
                inputsHTML.push(input);
            }
        }

        let btnContainer = document.createElement('div');
        btnContainer.className = 'confirm-buttons';

        let btnValidate = document.createElement('button');
        btnValidate.type = 'submit';
        btnValidate.className = 'btn btn-m right';
        btnValidate.onclick = function (e) {
            e.preventDefault();
            btnValidate.classList.add('loading');
            if (onConfirm) {
                if (!inputContainer.checkValidity()) {
                    btnValidate.classList.remove('loading');
                    inputContainer.reportValidity();
                    return false;
                }
                let data = new FormData();
                inputsHTML.forEach(input => {
                    if (input.type === "checkbox") {
                        data.append(input.name, input.checked ? "1" : "0");
                    } else if (input.type === "radio") {
                        if (input.checked) {
                            console.log(input);
                            data.append(input.name, input.value);
                        }
                    } else if (input.type === "file") {
                        if (input.files && input.files.length) {
                            data.append(input.name, input.files[0]);
                        }
                    } else {
                        data.append(input.name, input.value);
                    }
                });

                onConfirm(modal, data, btnValidate);
            } else {
                modal.dismiss();
                return true;
            }
        };
        btnValidate.innerHTML = confirmTextButton;

        let btnCancel = document.createElement('button');
        btnCancel.type = 'button';
        btnCancel.className = 'btn btn-m gr';
        btnCancel.onclick = function (e) {
            e.preventDefault();
            if (onCancel) {
                onCancel(modal);
            } else {
                modal.dismiss();
                return false;
            }
        };
        btnCancel.innerHTML = cancelTextButton;

        btnContainer.append(btnCancel);
        btnContainer.append(btnValidate);

        c.append(messageContainer);
        c.append(inputContainer);
        c.append(btnContainer);
        modal.setContentDom(c);
        modal.show();
    }

    /**
     *
     * @param context
     * @param switchTabEvent
     */
    static iniTabs(context, switchTabEvent, tabClassName = 'tab-link', tabPageClassName = 'tab-page') {
        let setTab = false;
        const links = context.getElementsByClassName(tabClassName);

        foreach(links, function (link) {
            // iniTabs already called
            if (link.hasAttribute("data-tab-initialized")) {
                setTab = true;
                return;
            }
            link.setAttribute("data-tab-initialized", "true");
            link.addEventListener('click', function (e) {
                // Ajouter l'attribut "data-noprevent" pour conserver l'action par défaut du lien plutôt que le système d'onglet.
                if (!link.hasAttribute('data-noprevent')) {
                    e.preventDefault();
                    Ui.setTab(context, this, switchTabEvent, true, tabClassName, tabPageClassName);
                }
            });
            if (!setTab && window.location.hash && "#" + link.getAttribute('data-target') === window.location.hash) {
                setTab = true;
                Ui.setTab(context, link, switchTabEvent, false, tabClassName, tabPageClassName);
            }
        });

        if (!setTab && links.length > 0) {
            Ui.setTab(context, links.item(0), switchTabEvent, false);
        }

        foreach(context.getElementsByClassName(tabPageClassName), function (page) {
            const subLinks = page.getElementsByClassName("tab-sublink");
            // Initialise les sous-onglets si existants
            if (subLinks.length > 0) {
                foreach(subLinks, (subLink, i) => {
                    if (i === 0) {
                        Ui.setTab(page, subLink, null, false, "tab-sublink", "tab-subpage");
                    }
                    subLink.addEventListener("click", () => {
                        Ui.setTab(page, subLink, null, false, "tab-sublink", "tab-subpage");
                    })
                });
            }
        });
    }

    /**
     *
     * @param context
     * @param linkElement
     * @param switchTabEvent
     * @param history
     * @param switchTabEvent
     * @param history
     * @param tabClassName
     */
    static setTab(context, linkElement, switchTabEvent, history = true, tabClassName = "tab-link", tabPageClassName = "tab-page") {
        let target;

        foreach(context.getElementsByClassName(tabClassName), function (link) {
            link.classList.remove('active'); // On désactive tous les onglets
            if (typeof linkElement === 'string') {
                if (link.getAttribute('data-target') === linkElement) {
                    target = linkElement;
                    linkElement = link;
                }
            }
        });

        linkElement.classList.add('active');

        if (!target) {
            target = linkElement.getAttribute('data-target');
        }

        if (switchTabEvent) {
            switchTabEvent(target);
        }

        if (history) {
            window.history.replaceState(null, null, "#" + target);
        }

        foreach(context.getElementsByClassName(tabPageClassName), function (page) {
            if (page.getAttribute('data-tab') === target) {
                page.classList.add('active');
            } else {
                page.classList.remove('active');
            }
        });
    }

    static circleLoader(domElement) {
        domElement.innerHTML = '<div class="loader-container"><div class="loader"><svg class="circular-loader" viewBox="25 25 50 50" >' +
            '<circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke-width="4" />' +
            '</svg></div></div>';
    }

    static getVisibleElements(elements, scrollPos, offset) {
        let visibleElements = [];
        for (var i = 0; i < elements.length; i++) {
            const imgContainer = elements[i];
            if (imgContainer.offsetTop <= scrollPos + offset && imgContainer.offsetTop > scrollPos - 100) {
                visibleElements.push(imgContainer);
            }
        }
        return visibleElements;
    }

    static setInputFilter(textbox, inputFilter, callback) {
        ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
            textbox.addEventListener(event, function () {
                if (inputFilter(this.value)) {
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                } else {
                    this.value = "";
                }
                if (callback) {
                    callback(this.value);
                }
            });
        });
    }

    static bindDropdowns(container = document) {
        foreach('dropdown', function (element) {
            const btn = element.getElementsByClassName('dropdown-btn')[0];
            const menu = element.getElementsByClassName('dropdown-menu')[0];

            if (btn && !btn.getAttribute('dropdown-bind')) {
                btn.addEventListener('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!menu.classList.contains("active")) {
                        menu.classList.add('active');

                        if (!menu.classList.contains("default-position")) {
                            menu.style.position = 'fixed';
                            menu.style.right = 'auto';

                            const rect = btn.getBoundingClientRect();
                            if (rect.bottom + menu.offsetHeight > window.innerHeight) {
                                menu.style.top = rect.top - menu.offsetHeight + 'px';
                            } else {
                                menu.style.top = rect.bottom + 'px';
                            }

                            if (rect.left + menu.offsetWidth > window.innerWidth) {
                                menu.style.left = rect.right - menu.offsetWidth + 'px';
                            } else {
                                menu.style.left = rect.left + 'px';
                            }
                        }

                        setTimeout(() => {
                            menu.classList.add('pop');
                        }, 140);
                        document.body.addEventListener('click', actDropDown);
                    } else {
                        actDropDown(e);
                    }
                });
                btn.setAttribute("dropdown-bind", "bind");
            }

            function actDropDown(e) {
                if (e.target !== menu && !isDescendant(menu, e.target)) {
                    menu.classList.remove('pop');
                    setTimeout(() => {
                        menu.classList.remove('active', 'pop');
                    }, 90);
                    document.body.removeEventListener('click', actDropDown);
                }
            }
        }, 0, container);
    }

    static initUiTheme() {
        var themePreference = localStorage.getItem("uiThemePreference");
        if (themePreference === "dark" || (!themePreference && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            oid('dark-mode-selector').checked = true;
            setThemeMode("dark");
        }

        oid('dark-mode-selector').onchange = (e) => {
            setThemeMode(oid('dark-mode-selector').checked ? "dark" : "default");
        }

        function setThemeMode(theme) {
            if (theme === "dark") {
                document.body.classList.add('dark-mode');
            } else {
                theme = 'default';
                document.body.classList.remove('dark-mode');
            }
            localStorage.setItem("uiThemePreference", theme);
            setCookie("uiThemePreference", theme);
            document.documentElement.setAttribute('data-theme', theme);
        }
    }

    static initFormLoaders() {
        var forms = document.forms;
        if (forms?.length) {
            for (let i = 0; i <= forms.length; i++) {
                (function (e) {
                    var form = document.forms[i];
                    if (form) {
                        var btn = form.querySelector('button[type="submit"]');
                        if (form && btn && !btn.classList.contains('noloader')) {
                            form.addEventListener('submit', (e) => {
                                // ajoute la classe "loading" sur le bouton submit du formulaire posté
                                btn.classList.add('loading');
                            });
                        }
                    }
                })(i);
            }
        }
    }

    static loadingModal(message = '') {
        const modal = new Modal({
            size: 'medium',
            modalId: 'loading',
            hideCloseButton: true,
        });

        modal.startLoad();
        modal.show();
        if (message.length) {
            var p = document.createElement('p');
            p.innerHTML = message;
            p.className = "center text-gray";
            modal.modalContent.appendChild(p);
        }
        return modal;
    }
}

module.exports = Ui;
global.Ui = Ui;
