<template>
  <div class="custom-select dropdown" :class="cssClass"
       @blur="closeSelect" v-click-outside="closeSelect">

    <div v-if="filter" @click="open ? closeSelect : open = true">
      <input v-model="inputQuery" :class="cssClassInput + (chevron ? ' with-chevron' : '')" :disabled="disabled" :placeholder="placeholder"
             @input="open = true; resIndex = -1; preventQueryFilter = false"
             @keydown.enter="$emit('input', selected); inputQuery = selectedOptionValue; closeSelect();"
             @keydown.down="navToNextResult"
             @keydown.up="navToPreviousResult"/>
      <svg class="chevron" viewBox="0 0 10 6" width="10" height="6" xmlns="http://www.w3.org/2000/svg"
           style="height: 6px; width: 20px;" v-if="chevron">
        <path
            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893C10.0976 0.683417 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"></path>
      </svg>
    </div>

    <div v-else :class="cssClassInput + ' ' + (disabled ? 'disabled' : '')" @click="open = !open">
      <span class="input_query" :class="chevron ? 'with-chevron' : '' ">{{ inputQuery }}</span>
      <span class="text-placeholder" v-if="!inputQuery && placeholder">{{ placeholder }}</span>
      <svg class="chevron" viewBox="0 0 10 6" width="10" height="6" xmlns="http://www.w3.org/2000/svg"
           style="height: 6px; width: 20px;" v-if="chevron && filteredOptions.length">
        <path
            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893C10.0976 0.683417 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"></path>
      </svg>
    </div>

    <div class="dropdown-menu items" :class="open ? 'open pop': ''" v-if="options.length && filteredOptions.length">
      <div v-for="(option, i) of filteredOptions" :key="i" :title="getOptionValue(option)" class="nav-action"
           @click="(multipleSelection && selected.includes(option)) ? unselectOption(option) : selectOption(option, multipleSelection && option !== firstOption);"
           :class="isSelected(option) ? 'selected' : ''"
           v-html="filter ? markFiltered(getOptionValue(option, true), !preventQueryFilter && inputQuery.length > 0 ? inputQuery : (selected ? getOptionValue(selected, true) : '')) : getOptionValue(option, true)">
      </div>
    </div>

    <input type="hidden" v-if="inputName || inputId" :name="inputName" :id="inputId" :value="selectedOptionKey" :class="inputClass" class="filter"/>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Liste des options disponibles
     */
    options: {
      type: Array,
      required: true,
    },
    /**
     * Class css appliquée sur le select
     */
    classname: {
      type: String,
      required: false,
      default: "",
    },
    /**
     * Valeur sélectionnée par défaut
     */
    default: {
      required: false,
      default: null,
    },
    /**
     * Si les résultats sont filtrés en tapant la requête (input text)
     */
    filter: {
      type: Boolean,
      required: false,
      default: false
    },
    filterOnlyFromStart: {
      type: Boolean,
      default: true
    },
    /**
     * Placeholder sur le champ texte
     */
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Si 'default' n'est pas défini, sélectionne par défaut la première option
     */
    firstAsDefault: {
      type: Boolean,
      required: false,
      default: true
    },
    firstOption: {
      required: false,
      default: null
    },
    /**
     * Dans le cas ou options est une liste d'objet obj: {key: foe, value: bar}, défini le nom de la clé "key"
     */
    optionKey: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Dans le cas ou options est une liste d'objet obj: {key: foe, value: bar}, défini le nom de la clé "value"
     */
    optionValue: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Affiche ou non l'icone dans le select
     */
    chevron: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Classe CSS de l'input
     */
    cssClassInput: {
      type: String,
      default: "input"
    },
    /**
     * Input name du champ caché
     */
    inputName: {
      type: String,
      required: false
    },
    inputId: {
      type: String,
      required: false
    },
    inputClass: {
      type: String,
      required: false
    },
    /**
     * Authorise la sélection multiple
     */
    multipleSelection: {
      type: Boolean,
      default: false
    },
    /**
     * Détermine si le sélecteur est désactivé ou non
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Override la valeur de la première option du sélecteur
     */
    firstOptionValue: {
      type: String,
      default: null
    }
  },
  watch: {
    options: function () {
      this.initOptions();
      const selected = this.initialSelection();
      if (this.multipleSelection) {
        for (let opt in selected) {
          this.selectOption(selected[opt]);
        }
        this.inputQuery = this.getOptionValue(this.selected);
      } else {
        this.selectOption(selected);
      }
    },
    open: function (isOpen, wasOpen) {
      if (wasOpen && !isOpen) {
        if (!this.inputQuery && this.selected !== null) {
          this.inputQuery = this.getOptionValue(this.selected);
        }
      } else if (this.disabled) {
        this.open = false;
      } else if (this.filter && isOpen) {
        this.inputQuery = '';
        for (let i in this.filteredOptions) {
          if (this.filteredOptions.hasOwnProperty(i)) {
            if (this.getOptionKey(this.filteredOptions[i]) === this.getOptionKey(this.selected)) {
              this.resIndex = i;
            }
          }
        }
      }
    },
    resIndex: function (nIndex) {
      for (let i in this.filteredOptions) {
        if (this.filteredOptions.hasOwnProperty(i)) {
          if (parseInt(i) === nIndex) {
            this.selected = this.filteredOptions[i];
          }
        }
      }
    }
  },
  data() {
    return {
      inputQuery: '',
      selected: this.multipleSelection ? [] : null,
      open: false,
      resIndex: -1,
      preventQueryFilter: true,
      init: false
    }
  },
  computed: {
    filteredOptions() {
      if (this.filter && this.inputQuery && (!this.selected || this.inputQuery !== this.getOptionValue(this.selected))) {
        return this.options.filter(option => {
          if (this.filterOnlyFromStart) {
            return this.getOptionValue(option).toLowerCase().startsWith(this.inputQuery.toLowerCase()) ||
                this.getOptionKey(option).startsWith(this.inputQuery.toLowerCase())
          } else {
            return this.getOptionValue(option).toLowerCase().includes(this.inputQuery.toLowerCase()) ||
                this.getOptionKey(option).startsWith(this.inputQuery.toLowerCase())
          }
        });
      }

      return this.options;
    },
    cssClass() {
      return (this.open ? "open " : "") + this.classname;
    },
    selectedOptionValue() {
      return this.getOptionValue(this.selected);
    },
    selectedOptionKey() {
      if (this.multipleSelection && this.selected) {
        return this.selected.map(s => this.getOptionKey(s)).join(',');
      } else {
        return this.getOptionKey(this.selected);
      }
    }
  },
  /**
   * Montage du composant
   */
  mounted() {
    this.initOptions();
    const selected = this.initialSelection();

    if (selected) {
      if (Array.isArray(selected)) {
        for (let opt in selected) {
          this.selectOption(selected[opt]);
        }
        this.inputQuery = this.getOptionValue(this.selected);
      } else {
        this.selectOption(selected);
      }
    }

    this.init = true;
  },
  methods: {
    /**
     * Ajoute la première option qui est souvent l'option "All"
     */
    initOptions() {
      this.selected = this.multipleSelection ? [] : null;
      if (this.firstOption && !this.options.find(option => this.getOptionKey(option) === this.getOptionKey(this.firstOption))) {
        this.options.unshift(this.firstOption);
      }
    },
    /**
     * Désélectionne une option dans le cas d'un sélecteur multiple
     *
     * @param option
     */
    unselectOption(option) {
      if (this.multipleSelection) {
        if (this.selected.includes(option)) {
          this.selected = this.selected.filter(o => o !== option);
        }
        if (this.selected.length === 0 && this.firstOption) {
          this.selectOption(this.firstOption);
        }
      }
      this.inputQuery = this.getOptionValue(this.selected);
      if (this.init) {
        this.$emit('input', this.selected)
      }
    },
    /**
     * Détermine si une option est sélectionnée
     *
     * @param option
     */
    isSelected(option) {
      if (this.multipleSelection) {
        return this.selected.includes(option);
      } else {
        return this.selected === option;
      }
    },
    /**
     * Sélectionne une option et ouvre / ferme le menu
     *
     * @param option
     * @param open
     */
    selectOption(option, open = false) {
      if (this.multipleSelection) {

        if (this.firstOption) {
          if (this.getOptionValue(this.firstOption) === this.getOptionValue(option)) {
            // déselectionne tout
            this.selected = [];
          } else if (this.selected.includes(this.firstOption)) {
            this.selected = this.selected.filter((option) => option !== this.firstOption);
          }
        }

        if (!this.selected.includes(option)) {
          this.selected.push(option);
        }
        if (this.firstOption) {
          if (option !== this.firstOption && this.isSelected(this.firstOption)) {
            // Déselection de l'option par défaut
            this.unselectOption(this.firstOption);
          }
          if (option === this.firstOption) {
            // Déselection des toutes les options sauf celle par défaut
            this.selected = [this.firstOption];
          }
        }
      } else {
        this.selected = option;
        this.inputQuery = this.getOptionValue(this.selected);
      }
      this.open = open;
      if (this.init) {
        this.$emit('input', this.selected)
      }
    },
    /**
     * Retourne la valeur par défaut de la sélection
     *
     * @returns {*[]|*|*[]|null}
     */
    initialSelection() {
      if (this.default !== null && this.default != 0) {
        if (this.multipleSelection) {
          return this.options.filter(option => {
            return this.getDefaultKey().includes(this.getOptionKey(option))
          });
        } else {
          const option = this.options.find(option => this.getOptionKey(option) === this.getDefaultKey());
          if (option) {
            return option;
          }
        }
      }

      if (this.options && this.options.length > 0 && this.firstAsDefault === true) {
        // Première option sélectionnée par défaut
        return this.multipleSelection ? [this.options[0]] : this.options[0];
      }

      return this.multipleSelection ? [] : null;
    },
    /**
     * Retourne la clef par défaut des options
     *
     * @returns {*}
     */
    getDefaultKey() {
      if (this.multipleSelection && this.selected) {
        let formattedDefault;
        switch (typeof this.default) {
          case "string":
            formattedDefault = this.default.split(",");
            break;
          case "object":
            formattedDefault = Object.values(this.default);
            break;
          default:
            formattedDefault = this.default;
            break;
        }

        return formattedDefault.map(s => typeof s === "object" ? this.getOptionKey(s) : s);
      } else {
        if (typeof this.default === "object") {
          return this.getOptionKey(this.default);
        }
        return this.default.toString();
      }
    },
    /**
     * Ferme le sélecteur
     */
    closeSelect() {
      this.open = false;
      if (this.multipleSelection) {
        this.inputQuery = this.getOptionValue(this.selected);
      }
    },
    /**
     * Affiche le contenu d'une option en gras si celle-ci a été filtrée avec succès
     *
     * @param optionName
     * @param query
     * @returns {*}
     */
    markFiltered(optionName, query) {
      const img = optionName.match(/<img[^>]*>/g);
      optionName = optionName.replace(/<img[^>]*>/g, "");
      return (img ? img[0] : "") + optionName.replace(new RegExp(query, "i"), '<b class="filtered">$&</b>');
    },
    getOptionKey(option) {
      if (this.optionKey && option !== null) {
        return option[this.optionKey] ? option[this.optionKey].toString() : '';
      }
      return option;
    },
    getOptionValue(option, html = false) {
      if (option == this.firstOption && this.firstOptionValue !== null) {
        return this.firstOptionValue;
      }
      if (Array.isArray(option)) {
        return option.map(o => this.getOptionValue(o, html)).join(", ");
      } else {
        if (this.optionValue && option !== null) {
          let icon = "";
          if (html && option.hasOwnProperty('icon')) {
            icon = '<img class="option-icon" width="24" height="24" src="' + option.icon + '"/>';
          }
          return icon + option[this.optionValue];
        }
        return option;
      }
    },
    resetInputQueryOnAction() {
      if (this.getOptionValue(this.selected) === this.inputQuery) {
        this.inputQuery = '';
      }
    },
    navToNextResult(e) {
      this.preventQueryFilter = true;
      this.resetInputQueryOnAction();
      if (this.filteredOptions && this.resIndex < this.filteredOptions.length - 1) {
        this.resIndex++;
      }
      if (!this.open) {
        this.open = true;
      }
    },
    navToPreviousResult(e) {
      this.resetInputQueryOnAction();
      this.preventQueryFilter = true;
      if (this.resIndex > 0) {
        this.resIndex--;
      }
      if (!this.open) {
        this.open = true;
      }
    },
    onBlur(e) {
      console.debug("on blur")
      setTimeout(() => this.closeSelect(), 100);
    }
  }
}
</script>

<style lang="scss" scoped>

.custom-select.dropdown {
  min-width: 80px;
  font-size: 0.875rem;
}

.dropdown-menu {
  max-height: 300px;
  overflow: auto;
  width: 100%;
  min-width: 200px;
  max-width: 600px;
  right: 0;
}

.dropdown .input {
  min-height: 100%;
  height: calc(2.25rem + 2px);
  font-size: 0.875rem;

  &:not(.disabled) {
    cursor: pointer;
  }

  &.with-chevron {
    padding-right: 18px;
    text-overflow: ellipsis;
  }
}

.input_query {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;

  &.with-chevron {
    max-width: calc(100% - 20px);
  }
}

</style>
