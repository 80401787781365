var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeSelect,
          expression: "closeSelect",
        },
      ],
      staticClass: "custom-select dropdown",
      class: _vm.cssClass,
      on: { blur: _vm.closeSelect },
    },
    [
      _vm.filter
        ? _c(
            "div",
            {
              on: {
                click: function ($event) {
                  _vm.open ? _vm.closeSelect : (_vm.open = true)
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputQuery,
                    expression: "inputQuery",
                  },
                ],
                class: _vm.cssClassInput + (_vm.chevron ? " with-chevron" : ""),
                attrs: { disabled: _vm.disabled, placeholder: _vm.placeholder },
                domProps: { value: _vm.inputQuery },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.inputQuery = $event.target.value
                    },
                    function ($event) {
                      _vm.open = true
                      _vm.resIndex = -1
                      _vm.preventQueryFilter = false
                    },
                  ],
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      _vm.$emit("input", _vm.selected)
                      _vm.inputQuery = _vm.selectedOptionValue
                      _vm.closeSelect()
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      )
                        return null
                      return _vm.navToNextResult.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      )
                        return null
                      return _vm.navToPreviousResult.apply(null, arguments)
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _vm.chevron
                ? _c(
                    "svg",
                    {
                      staticClass: "chevron",
                      staticStyle: { height: "6px", width: "20px" },
                      attrs: {
                        viewBox: "0 0 10 6",
                        width: "10",
                        height: "6",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893C10.0976 0.683417 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _c(
            "div",
            {
              class: _vm.cssClassInput + " " + (_vm.disabled ? "disabled" : ""),
              on: {
                click: function ($event) {
                  _vm.open = !_vm.open
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "input_query",
                  class: _vm.chevron ? "with-chevron" : "",
                },
                [_vm._v(_vm._s(_vm.inputQuery))]
              ),
              _vm._v(" "),
              !_vm.inputQuery && _vm.placeholder
                ? _c("span", { staticClass: "text-placeholder" }, [
                    _vm._v(_vm._s(_vm.placeholder)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.chevron && _vm.filteredOptions.length
                ? _c(
                    "svg",
                    {
                      staticClass: "chevron",
                      staticStyle: { height: "6px", width: "20px" },
                      attrs: {
                        viewBox: "0 0 10 6",
                        width: "10",
                        height: "6",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893C10.0976 0.683417 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
      _vm._v(" "),
      _vm.options.length && _vm.filteredOptions.length
        ? _c(
            "div",
            {
              staticClass: "dropdown-menu items",
              class: _vm.open ? "open pop" : "",
            },
            _vm._l(_vm.filteredOptions, function (option, i) {
              return _c("div", {
                key: i,
                staticClass: "nav-action",
                class: _vm.isSelected(option) ? "selected" : "",
                attrs: { title: _vm.getOptionValue(option) },
                domProps: {
                  innerHTML: _vm._s(
                    _vm.filter
                      ? _vm.markFiltered(
                          _vm.getOptionValue(option, true),
                          !_vm.preventQueryFilter && _vm.inputQuery.length > 0
                            ? _vm.inputQuery
                            : _vm.selected
                            ? _vm.getOptionValue(_vm.selected, true)
                            : ""
                        )
                      : _vm.getOptionValue(option, true)
                  ),
                },
                on: {
                  click: function ($event) {
                    _vm.multipleSelection && _vm.selected.includes(option)
                      ? _vm.unselectOption(option)
                      : _vm.selectOption(
                          option,
                          _vm.multipleSelection && option !== _vm.firstOption
                        )
                  },
                },
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.inputName || _vm.inputId
        ? _c("input", {
            staticClass: "filter",
            class: _vm.inputClass,
            attrs: { type: "hidden", name: _vm.inputName, id: _vm.inputId },
            domProps: { value: _vm.selectedOptionKey },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }